<template>
  <product-page :title="title" :subtitle="subtitle">
    <multi-features-box :features="mobileFeatures" />
    <contact-popup topic="Aplikacje mobilne" />
  </product-page>
</template>

<script>
import ProductPage from "@/components/products/ProductPage";
import MultiFeaturesBox from "@/components/products/common/MultiFeaturesBox";
import ContactPopup from "@/components/ContactPopup";

export default {
  components: {ContactPopup, MultiFeaturesBox, ProductPage},
  data() {
    //TODO: missing translations or restful request
    return {
      title: "Dedykowane aplikacje mobilne",
      subtitle: "Firma Platforma Biznesu specjalizuje się w wytwarzaniu aplikacji dedykowanych urządzeniom mobilnym Android oraz iOS.",
      mobileFeatures: [

      ]
    }
  },
}
</script>
